<template>
  <el-main>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="话费充值须知" name="first">
          <el-form-item label="话费充值须知">
            <template>
              <RichText editorId="charge_notice" v-if="activeName === 'first'" :richTxt="ruleForm.charge_notice" @getContent="(e, type) => (ruleForm[`${type}`] = e)"></RichText>
            </template>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="油卡充值须知" name="second">
          <el-form-item label="油卡充值须知">
            <template>
              <RichText editorId="oil_notice" v-if="activeName === 'second'" :richTxt="ruleForm.oil_notice" @getContent="(e, type) => (ruleForm[`${type}`] = e)"></RichText>
            </template>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      activeName: 'first',
      ruleForm: {
        charge_notice: '',
        oil_notice: '',
      },
      rules: {},
    };
  },
  created() {
    this.getSetInfo();
  },
  methods: {
    preservation() {
      this.$axios.post(this.$api.mathematical.setRule, this.ruleForm).then(res => {
        if (res.code == 0) {
          this.$message.success('设置成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getSetInfo() {
      this.$axios.post(this.$api.mathematical.getRule).then(res => {
        if (res.code == 0) {
          this.ruleForm.charge_notice = res.result.charge_notice;
          this.ruleForm.oil_notice = res.result.oil_notice;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background-color: #fff;
}
</style>
